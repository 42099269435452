import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import {
  format, isValid, isSameMonth,
  differenceInMilliseconds,
} from 'date-fns/esm';
import { TagCloud } from 'react-tagcloud';

import { constructLink } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import './cloud.css';

import styles from './styles.module.css';

class ArticleMotherLayout extends React.PureComponent {

  static formCounts(data, color) {

    const counts = [];
    data.forEach(item => {

      if (item && item.tags) {

        item.tags.forEach(tag => {

          let exists = false;
          counts.some((t, i) => {

            if (t.value === tag) {

              counts[i].count += 1;
              exists = true;

            }

            return exists;

          });

          if (exists === false) {

            counts.push({
              value: tag,
              count: 1,
              color,
            });

          }

        });

      }

    });

    return counts;

  }

  constructor(props) {

    super(props);

    let articleCount;
    if (props.section && props.section.styles) ({ articleCount } = props.section.styles);
    const counts = ArticleMotherLayout
      .formCounts(props.section.articles, props.themeData.colors[0]);
    const pageCount = Math.ceil((props.section.articles.length - 1) / articleCount);

    this.state = {
      articleCount,
      pageCount,
      currentPage: 0,
      searchResults: null,
      counts,
    };

    this.createText = this.createText.bind(this);
    this.createImage = this.createImage.bind(this);
    this.handleStringSearch = this.handleStringSearch.bind(this);
    this.handleDateSearch = this.handleDateSearch.bind(this);
    this.handleTagSearch = this.handleTagSearch.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.createAuthorInfo = this.createAuthorInfo.bind(this);
    this.createSearch = this.createSearch.bind(this);
    this.createArchive = this.createArchive.bind(this);
    this.createTagCloud = this.createTagCloud.bind(this);
    this.createArticles = this.createArticles.bind(this);
    this.createSearchHeader = this.createSearchHeader.bind(this);
    this.createSearchResults = this.createSearchResults.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

  }

  createText(item, type, index, subIndex) {

    const id = `${this.props.section._id}_${type.startsWith('TITLE') ? 'Title' : 'Paragraph'}_${subIndex !== undefined ? subIndex : ''}${index}_section`;
    let content;
    let styleNames;
    if (type === 'TITLE') {

      content = `<h3>${item}</h3>`;
      styleNames = styles.articleTitle;

    } else if (type.startsWith('PARAGRAPH')) {

      content = `<span>${item}</span>`;
      styleNames = styles.articleText;

    }

    const text = (
      <div>
        {
          HTMLParser(content)
        }
      </div>
    );

    const elem = (
      <div
        id={id}
        key={id}
        className={styleNames}
      >
        { text }
      </div>
    );

    return elem;

  }

  createImage(item, index, subIndex) {

    let elem;
    if (item.icon !== null || item.id !== '') {

      const wrapper = `articleImageWrapper${item.icon ? 'Icon' : ''}`;
      const imgWrapper = 'imageContent5';
      const img = 'articleImage';

      elem = (
        <div
          className={styles[wrapper]}
          key={`${this.props.section._id}_Image}_${subIndex !== undefined ? subIndex : ''}${index}_section`}
        >
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyle={imgWrapper}
            elementStyle={img}
            iconStyle=""
            src={item.CDNLink ? item.CDNLink : `${process.env.IMAGES_CDN}/${item.src}`}
            alt={item.alt}
            data={item}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            colors={this.props.themeData.colors}
          />
        </div>
      );

    }

    return elem;

  }

  handleStringSearch(event) {

    if (event.type === 'mousedown' || (event.type === 'keypress' && event.key === 'Enter')) {

      const articles = [];
      let { value } = event.target;
      if (event.type === 'mousedown') {

        ({ value } = document.getElementById(`${this.props.section._id}_search`));

      }
      const origValue = value;
      value = value.trim().toLowerCase();

      if (value) {

        this.props.section.articles.forEach((article, index) => {

          if (
            article.title.toLowerCase().includes(value)
            || article.excerpt.toLowerCase().includes(value)
            || (
              article.author
              && article.author.name
              && article.author.name.toLowerCase().includes(value)
            )
            || article.tags.includes(value)
          ) {

            articles.push(index);

          } else {

            article.data.every(cntnt => {

              let notFound = true;
              if (
                (
                  (cntnt.type === 'PARAGRAPH/PARAGRAPH' || cntnt.type === 'PARAGRAPH/QUOTE' || cntnt.type === 'HEADINGS/HEADING-TWO')
                  && cntnt.text.toLowerCase().includes(value)
                )
                || (cntnt.type === 'IMAGE' && cntnt.content && cntnt.content.alt && cntnt.content.alt.toLowerCase().includes(value))
              ) {

                articles.push(index);
                notFound = false;

              }

              return notFound;

            });

          }

        });

        const pageCount = Math.ceil(articles.length / this.state.articleCount);
        this.setState({
          searchResults: {
            type: 'search',
            search: origValue,
            articles,
          },
          pageCount,
          currentPage: 0,
        });

      } else this.clearSearch();

    }

  }

  handleDateSearch(event) {

    const { value } = event.target.options[event.target.selectedIndex].dataset;

    if (value !== 'select') {

      const articles = [];
      this.props.section.articles.forEach((article, index) => {

        if (isSameMonth(new Date(value), new Date(article.pubDate))) articles.push(index);

      });

      const pageCount = Math.ceil(articles.length / this.state.articleCount);
      this.setState({
        searchResults: {
          type: 'archive',
          search: value,
          articles,
        },
        pageCount,
        currentPage: 0,
      });

    } else this.clearSearch();

  }

  handleTagSearch(event) {

    const { value } = event.target !== undefined ? event.target.dataset : event;
    let articles = null;
    articles = [];
    this.props.section.articles.forEach((article, index) => {

      if (article.tags.includes(value)) articles.push(index);

    });

    const pageCount = Math.ceil(articles.length / this.state.articleCount);
    this.setState({
      searchResults: {
        type: 'tag',
        search: value,
        articles,
      },
      pageCount,
      currentPage: 0,
    });

  }

  handlePageClick(data) {

    const currentPage = data.selected;
    this.setState({
      currentPage,
    });

  }

  createAuthorInfo(data) {

    const author = this.props.section.styles.authors.find(a => a._id === data.owner);
    let value;
    if (author !== undefined) {

      const links = [];
      if (author.links.length > 0) {

        author.links.forEach((l, i) => {

          if (l.active === true && l.image.icon !== null) {

            const {
              link,
            } = constructLink(l.image.linkObj, this.props.pagePathList, this.props.articlePathList);

            let tab;
            if (l.image.linkObj) tab = l.image.linkObj.openLinkInNewTab;

            const newLink = (
              <a
                key={`${this.props.section._id}_ArticleMother_Owner_Link_${i}`}
                href={link}
                target={tab ? '_blank' : '_self'}
                rel={tab ? 'noopener noreferrer' : ''}
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(newLink);

          }

          return null;

        });

      }

      value = (
        <div>
          {
            author.image !== ''
            && (
              <div className={styles.authorImage}>
                <SectionMedia
                  mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                  wrapperStyle="imageContent3"
                  elementStyle=""
                  iconStyle=""
                  src={author.image.CDNLink
                    ? author.image.CDNLink
                    : `${process.env.IMAGES_CDN}/${author.image.src}`}
                  alt={author.image.alt}
                  data={author.image}
                  images={this.props.images}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                  filePathList={this.props.filePathList}
                  colors={this.props.themeData.colors}
                />
              </div>
            )
          }
          {
            author.name !== ''
            && (
              <div className={styles.textCenter}>
                <h4
                  className={`${styles.Subtitle} ${styles.authorText}`}
                  style={{ ...this.state.themeHeadingStyle, ...this.state.color3 }}
                >
                  {author.name}
                </h4>
              </div>
            )
          }
          {
            author.desc !== ''
            && (
              <div className={styles.textCenter}>
                <span
                  className={`${styles.Body} ${styles.authorText}`}
                  style={this.state.themeDefaultStyle}
                >
                  { HTMLParser(author.desc) }
                </span>
              </div>
            )
          }
          {
            links.length > 0
            && (
              <div className={styles.linksWrapper}>
                { links }
              </div>
            )
          }
        </div>
      );

    }

    return value;

  }

  createSearch() {

    const value = (
      <div className={styles.searchWrapper}>
        <input
          id={`${this.props.section._id}_search`}
          type="text"
          placeholder={this.props.section.styles.searchText}
          className={styles.searchInput}
          onKeyPress={this.handleStringSearch}
        />
        <button
          className={styles.searchBtn}
          onMouseDown={this.handleStringSearch}
        >
          <i className="entypo icon-search" />
        </button>
      </div>
    );

    return value;

  }

  createArchive(title) {

    const months = [];
    const options = [
      (
        <option
          data-value="select"
          key={`${this.props.section._id}_Archive_Select`}
        >
          { this.props.section.styles.selectText }
        </option>
      ),
    ];

    this.props.section.articles.forEach(item => {

      let found = false;
      months.some(month => {

        if (isSameMonth(new Date(month), new Date(item.pubDate))) {

          found = true;

        }

        return found;

      });

      if (found === false) {

        months.push(item.pubDate);

      }

    });

    months.sort((left, right) => (

      differenceInMilliseconds(Date.UTC(left), Date.UTC(right))

    ));


    months.forEach(month => {

      if (isValid(new Date(month))) {

        options.push(<option data-value={month} key={`${this.props.section._id}_Archive_${format(new Date(month), 'MM yyyy')}`}>{ format(new Date(month), 'MM yyyy') }</option>);

      }

    });

    const value = (
      <div className={styles.elementWrapper}>
        {
          title !== false
          && (
            <div className={styles.text}>
              <h3>
                { this.props.section.styles.archiveText }
              </h3>
            </div>
          )
        }
        <select className={styles.select} onChange={this.handleDateSearch}>{ options }</select>
      </div>
    );

    return value;

  }

  createTagCloud() {

    let value;
    if (this.state.counts.length > 0) {

      value = (
        <div className={styles.elementWrapper}>
          <div className={styles.text}>
            <h3>
              { this.props.section.styles.tagsText }
            </h3>
          </div>
          <TagCloud
            minSize={10}
            maxSize={35}
            disableRandomColor
            tags={this.state.counts}
            className="simple-cloud"
            onClick={this.handleTagSearch}
          />
        </div>
      );

    }

    return value;

  }

  createArticles() {

    const articles = [];
    const endIndex = (this.state.currentPage * this.state.articleCount) + this.state.articleCount
    > this.props.section.articles.length
      ? this.props.section.articles.length
      : (this.state.currentPage * this.state.articleCount) + this.state.articleCount;
    for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {

      const to = this.props.section.articles[i].link.target;

      let img;
      if (this.props.section.styles.showThumbnails === true) {

        img = (
          <div key={`${this.props.section._id}_article_${i}_image_link`}>
            <Link
              to={to}
            >
              { this.createImage(this.props.section.articles[i].image, i) }
            </Link>
          </div>
        );

      }

      let excerpt;
      if (this.props.section.styles.showExcerpt === true) {

        excerpt = this.createText(this.props.section.articles[i].excerpt, 'PARAGRAPH', i);

      }

      const pub = (
        <div key={`${this.props.section._id}_article_${i}_short_pub`}>
          <span>
            {format(new Date(this.props.section.articles[i].pubDate), 'd.M.yyyy')}
          </span>
        </div>
      );
      const title = (
        <div key={`${this.props.section._id}_article_${i}_title_link`}>
          <Link
            to={to}
          >
            { this.createText(this.props.section.articles[i].title, 'TITLE', i) }
          </Link>
        </div>
      );
      const link = (
        <div key={`${this.props.section._id}_article_${i}_link`}>
          <Link
            to={to}
          >
            {this.props.section.articles[i].link.text}
          </Link>
        </div>
      );
      const article = (
        <div
          key={`${this.props.section._id}_article_${i}_preview`}
          className={styles.articleWrapper}
        >
          { [img, pub, title, excerpt, link] }
          <div className={styles.line} />
        </div>

      );

      articles.push(article);

    }

    return articles;

  }

  createSearchHeader() {

    let titleText;
    let resultText;
    let field;
    if (this.state.searchResults.articles.length === 0) {

      titleText = this.props.section.styles.noResults;

    } else if (this.state.searchResults.type === 'search') {

      titleText = this.props.section.styles.searchResults;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.props.section.styles.searchResult} "${this.state.searchResults.search}"` }
        </div>
      );
      field = (
        <div className={styles.field}>
          { this.createSearch() }
        </div>
      );

    } else if (this.state.searchResults.type === 'archive') {

      const date = format(new Date(this.state.searchResults.search), 'MM yyyy');
      titleText = `${this.props.section.styles.archiveText}: ${date}`;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.props.section.styles.dateResult} ` }
          <span className={styles.field}>
            { date }
          </span>
        </div>
      );
      field = (
        <div className={styles.field}>
          { this.createArchive(false) }
        </div>
      );

    } else if (this.state.searchResults.type === 'tag') {

      titleText = `${this.props.section.styles.tag}: ${this.state.searchResults.search}`;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.props.section.styles.tagResult} ` }
          <span className={styles.field}>
            { this.state.searchResults.search }
          </span>
        </div>
      );

    }

    const header = (
      <React.Fragment>
        <div className={styles.searchTitle}>
          <h1>
            { titleText }
          </h1>
        </div>
        {
          this.state.searchResults.articles.length > 0
          && (
            <React.Fragment>
              <span>
                { resultText }
              </span>
              { field }
              <div className={styles.line} />
            </React.Fragment>
          )
        }
      </React.Fragment>
    );

    return header;

  }

  createSearchResults() {

    const articles = [];
    if (this.state.searchResults.articles.length > 0) {

      const endIndex = (this.state.currentPage * this.state.articleCount) + this.state.articleCount
      > this.state.searchResults.articles.length
        ? this.state.searchResults.articles.length
        : (this.state.currentPage * this.state.articleCount) + this.state.articleCount;
      for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {

        const index = this.state.searchResults.articles[i];
        const img = this.createImage(this.props.section.articles[index].image, index);
        const pub = format(new Date(this.props.section.articles[index].pubDate), 'd.M.yyyy');
        const tags = [];
        this.props.section.articles[index].tags.forEach(tag => {

          const btn = (
            <button
              style={{ color: this.props.themeData.colors[0] }}
              data-value={tag}
              className={styles.tagBtn}
              onMouseDown={this.handleTagSearch}
            >
              {tag}
            </button>
          );
          tags.push(btn);

        });
        const title = this.createText(this.props.section.articles[index].title, 'TITLE', index);
        const excerpt = this.createText(this.props.section.articles[index].excerpt, 'PARAGRAPH', index);
        const to = this.props.section.articles[index].link.target;

        const link = (
          <div key={`${this.props.section._id}_article_${index}_link`}>
            <Link
              id={`${this.props.section._id}_ArticleLink_${index}`}
              to={to}
            >
              { this.props.section.articles[index].link.text }
            </Link>
          </div>
        );
        const article = (
          <div
            key={`${this.props.section._id}_article_${index}_preview`}
            className={`row ${styles.articleWrapper}`}
          >
            <div className="col-12 col-sm-8">
              <div className={styles.dateTag}>
                <i className={`entypo icon-calendar ${styles.icon1}`} />
                { pub }
                <i className={`entypo icon-mouse ${styles.icon2}`} />
                <span>
                  {
                    tags.map((tag, idx) => {

                      let text = ', ';
                      if (idx === tags.length - 1) {

                        text = '';

                      }

                      return <span key={`${this.props.section._id}_${index}_TagSpan_${this.props.section.articles[index].tags[idx]}`}>{tag}{text}</span>;

                    })
                  }
                </span>
              </div>
              <div>
                { title }
              </div>
              <div>
                {excerpt}
              </div>
              <div>
                {link}
              </div>
            </div>
            <div className={`col-0 col-sm-4 ${styles.resultImage}`}>
              { img }
            </div>
            <div className={`col-12 ${styles.line}`} />
          </div>

        );

        articles.push(article);

      }

    }

    return articles;

  }

  clearSearch() {

    const index = this.props.section.articles.length - 1;
    const { articleCount } = this.props.section.articles[index];
    const pageCount = Math.ceil((this.props.section.articles.length - 1) / articleCount);

    this.setState({
      searchResults: null,
      pageCount,
      currentPage: 0,
    });

  }

  render() {

    const { sidebar } = this.props.section.styles;
    const sidebarActive = (sidebar.authorInfo === true || sidebar.search === true
      || sidebar.archive === true || sidebar.tags === true) && this.state.searchResults === null;
    const contentCols = sidebarActive === true ? `col-12 col-sm-8 ${styles.contentPadding}` : 'col-12';
    const otherCols = sidebarActive === true ? `col-12 col-sm-4 ${styles.otherPadding}` : undefined;

    let articles;
    let searchHeader;
    if (this.state.searchResults === null) {

      articles = this.createArticles();

    } else {

      searchHeader = this.createSearchHeader();
      articles = this.createSearchResults();

    }

    let authorInfo;
    let search;
    let archive;
    let tags;
    const data = this.props.section.styles;
    if (sidebar.authorInfo === true && this.state.searchResults === null) {

      authorInfo = this.createAuthorInfo(data);

    }

    if (sidebar.search === true && this.state.searchResults === null) {

      search = this.createSearch();

    }

    if (sidebar.archive === true && this.state.searchResults === null) {

      archive = this.createArchive();

    }

    if (sidebar.tags === true && this.state.searchResults === null) {

      tags = this.createTagCloud();

    }

    return (
      <div className="container">
        <div className="row">
          <div className={contentCols}>
            {
              this.state.searchResults !== null
              && (searchHeader)
            }
            { articles }
            {
              this.state.pageCount > 1
              && (
                <div className={styles.paginationWrapper}>
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={`${styles.pagination}`}
                    subContainerClassName="pages pagination"
                    disabledClassName={`${styles.disabledPagination}`}
                    activeClassName={`${styles.activePage}`}
                    initialPage={0}
                    disableInitialCallback
                    forcePage={this.state.currentPage}
                  />
                </div>
              )
            }
          </div>
          <div className={otherCols}>
            { authorInfo }
            { search }
            { archive }
            { tags }
          </div>
        </div>
      </div>
    );

  }

}

ArticleMotherLayout.propTypes = {
  page: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeData: PropTypes.shape({
    color: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ArticleMotherLayout;
