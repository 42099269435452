import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';

import styles from './styles.module.css';

class MenuLayout extends React.Component {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      columns,
    };

    this.createContent = this.createContent.bind(this);

  }

  createContent(item, index) {

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === ''
        && item.styles.backgroundColor.gradient.from === ''
      ) backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
        undefined,
        this.props.themeData.colors,
      );

    }

    if (this.props.layout === 'design2') style = { ...style, ...{ paddingBottom: '32px' } };
    const align = `align${item.align}`;
    let cols;
    if (this.state.columns === 1) cols = 'col-12';
    else {

      cols = this.props.layout === 'design2'
        ? `col-12${this.state.columns ? ` col-lg-${12 / this.state.columns}` : ''}`
        : `col-12 col-md-6 col-lg-${this.state.columns ? (12 / this.state.columns) : '4'}`;

    }
    const design = this.props.layout === 'design2' ? 'Design2' : '';

    const texts = [];
    let image;
    item.data.forEach((elem, i) => {

      if (elem.active && elem.type === 'IMAGES/IMAGE') {

        const move = elem && elem.content && elem.content.transform
          && (elem.content.transform.offsetX !== 0 || elem.content.transform.offsetY !== 0);

        image = (
          <div className={styles[`menuImageWrapper${design}`]}>
            <SectionMedia
              id={elem.content.id}
              mediaType={elem.content.icon ? 'ICON' : 'IMAGE'}
              wrapperStyle={`imageContent${move ? 'Move' : ''}`}
              elementStyle={move ? '' : this.props.layout !== 'design2' ? 'menuImage' : 'imageFull'}
              align={item.align}
              iconStyle=""
              src={elem.content.src}
              alt={elem.content.alt}
              data={elem.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              colors={this.props.themeData.colors}
            />
          </div>
        );

      } else if (elem.active && (elem.type.startsWith('HEADINGS/') || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let content;
        if (elem.type === 'PARAGRAPH/PARAGRAPH') content = `<span>${elem.text}</span>`;
        else if (elem.type === 'HEADINGS/HEADING-FOUR') content = `<h4>${elem.text}</h4>`;
        else content = `<h3>${elem.text}</h3>`;

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={`${styles[`menuComponentText${design}`]} ${styles[`${align}`]}`}
          >
            { HTMLParser(content) }
          </div>
        );
        texts.push(text);

      }

    });

    const content = (
      <div
        key={`${this.props.section._id}_layout_${index}`}
        className={`${cols} ${styles[`menuComponentWrapper${design}`]}`}
      >
        <div style={style}>
          { image }
          <div style={this.props.layout === 'left' && style ? { padding: '20px 10px' } : undefined}>
            { texts }
          </div>
        </div>
      </div>
    );

    return content;

  }

  render() {

    const activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.active && item.type === 'COMPONENT') {

        const content = this.createContent(item, index);
        activeComponents.push(content);

      } else if (item.active && item.type === 'HEADINGS/HEADING-TWO') {

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}`}
            className={`col-12 ${styles.menuMainHeader}`}
          >
            { HTMLParser(`<h2>${item.text}</h2>`) }
          </div>
        );

        top.push(text);

      }

      return null;

    });


    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`menuContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { activeComponents }
        </div>
      </div>
    );

  }

}

MenuLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
};

export default MenuLayout;
