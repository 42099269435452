import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

class Carousel extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      moving: '',
      selected: 0,
      selectedBefore: 0,
      timer: null,
      startPos: undefined,
    };

    this.selectNext = this.selectNext.bind(this);
    this.handleTouch = this.handleTouch.bind(this);

  }

  componentDidMount() {

    if (this.props.autoPlay) this.startTimer(this.props.interval);

  }

  componentWillUnmount() {

    if (this.state.timer) clearInterval(this.state.timer);

  }

  selectNext(e, direction) {

    if (e) e.stopPropagation();
    const selected = direction === 'R'
      ? (this.state.selected + 1) % this.props.children.length
      : this.state.selected < 1 ? this.props.children.length - 1 : this.state.selected - 1;

    if (this.props.autoPlay && !this.props.infiniteLoop
      && selected === this.props.children.length - 1) clearInterval(this.state.timer);

    this.setState({
      moving: direction,
      selected,
      selectedBefore: this.state.selected,
    });

  }

  startTimer(interval) {

    if (this.state.timer) clearInterval(this.state.timer);
    const timer = setInterval(() => this.selectNext(undefined, 'R'), interval);
    this.setState({
      timer,
    });

  }

  handleIndicatorClick(i) {

    this.setState({
      selected: i,
      moving: i < this.state.selected ? 'L' : i > this.state.selected ? 'R' : '',
      selectedBefore: this.state.selected,
    });

  }

  handleTouch(e, start) {

    if (e) {

      e.stopPropagation();
      const [client] = e.changedTouches;
      if (start && client) this.setState({ startPos: client.clientX });
      else if (client && this.state.startPos !== undefined) {

        const direction = this.state.startPos > client.clientX ? 'R' : 'L';
        this.selectNext(undefined, direction);
        this.setState({ startPos: undefined });

      }

    }

  }

  render() {

    const { moving, selected } = this.state;
    const childLength = this.props.children.length;
    let selectedItem = this.props.children[selected];

    const prevItemStyle = styles[`prevCarouselItem${moving}`];
    const selectedItemStyle = styles[`selectedCarouselItem${moving}`];
    const nextItemStyle = styles[`nextCarouselItem${moving}`];
    const preloadItemStyle = styles.nextCarouselItem;

    let preloadItemIndex = moving === 'L' ? selected - 1 : selected + 1;
    if (preloadItemIndex >= this.props.children.length) preloadItemIndex = 0;
    else if (preloadItemIndex < 0) preloadItemIndex = this.props.children.length - 1;
    const preloadItem = this.props.children[preloadItemIndex];

    const indicators = this.props.children.map((c, i) => (
        <div
          className={this.state.selected === i
            ? styles.carouselIndicatorSelected : styles.carouselIndicator}
          key={`carousel-indicator-${i}`}
          onClick={() => this.handleIndicatorClick(i)}
        />
    ));

    let arrows;
    if (this.props.arrowsPos && this.props.arrowsPos !== 'Sides' && this.props.arrowSize !== undefined) {

      arrows = (
        <div className={styles[`arrowsContainer${this.props.arrowsPos}`]}>
          <button
            className={styles[`smallArrowContainerPrev${this.props.arrowSize}`]}
            onClick={e => this.selectNext(e, 'L')}
          >
            <i className={`entypo icon-left-open ${styles[`icon${this.props.arrowSize}`]}`} />
          </button>
          <button
            className={styles[`smallArrowContainer${this.props.arrowSize}`]}
            onClick={e => this.selectNext(e, 'R')}
          >
            <i className={`entypo icon-right-open ${styles[`icon${this.props.arrowSize}`]}`} />
          </button>
        </div>
      );

    }

    let leftArrow;
    let rightArrow;

    if (this.props.arrowsPos === 'Sides') {

      selectedItem = (
        <div className={styles.multipleWrapper}>
          <div className={styles.multipleImage}>
            {this.props.children[this.state.moving === 'L' ? preloadItemIndex : selected]}
          </div>
          <div className={styles.multipleImage}>
            {this.props.children[this.state.moving === 'L' ? selected : preloadItemIndex]}
          </div>
        </div>
      );

    }

    if (this.props.showArrows || this.props.arrowsPos === 'Sides') {

      let containerPrev = !this.props.infiniteLoop && selected === 0
        ? 'prevArrowContainerHidden' : 'prevArrowContainer';
      let containerNext = !this.props.infiniteLoop && selected === (childLength - 1)
        ? 'nextArrowContainerHidden' : 'nextArrowContainer';
      let prevArrow = <div styleName={styles.prevArrow} />;
      let nextArrow = <div className={styles.nextArrow} />;

      if (this.props.arrowsPos === 'Sides') {

        containerPrev = 'sidesPrevContainer';
        containerNext = 'sidesNextContainer';
        prevArrow = <i className={`entypo icon-left-open ${styles.icon}`} />;
        nextArrow = <i className={`entypo icon-right-open ${styles.icon}`} />;

      }

      leftArrow = (
        <button
          className={styles[containerPrev]}
          onClick={e => this.selectNext(e, 'L')}
        >
          { prevArrow }
        </button>
      );

      rightArrow = (
        <button
          className={styles[containerNext]}
          onClick={e => this.selectNext(e, 'R')}
        >
          { nextArrow }
        </button>
      );

    }

    let containerStyle = this.props.mode === 'cards' ? styles.carouselContainerCards : styles.carouselContainer;
    if (this.props.arrowsPos === 'Sides') containerStyle = styles.carouselContainerSides;

    return (
      <div className={containerStyle}>
        { arrows }
        { leftArrow }
        {
          this.state.moving && this.props.arrowsPos !== 'Sides'
          && (
            <div
              className={this.state.moving === 'L' ? nextItemStyle : prevItemStyle}
              key={`oItem-${moving}-${selected}`}
            >
              {this.props.children[this.state.selectedBefore]}
            </div>
          )
        }
        <div
          className={selectedItemStyle}
          key={`sItem-${moving}-${selected}`}
          onTouchStart={e => this.handleTouch(e, true)}
          onTouchEnd={e => this.handleTouch(e, false)}
        >
          {selectedItem}
        </div>
        {
          this.props.arrowsPos !== 'Sides'
          && (
            <div
              className={preloadItemStyle}
              key={`ssItem-${moving}-${selected}`}
            >
              {preloadItem}
            </div>
          )
        }
        { rightArrow }
        {
          this.props.showIndicators
          && (
            <div className={styles.carouselIndicatorContainer}>
              {indicators}
            </div>
          )
        }
      </div>
    );

  }

}

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  showArrows: PropTypes.bool,
  showIndicators: PropTypes.bool,
  autoPlay: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  interval: PropTypes.number,
  noEvents: PropTypes.bool,
  mode: PropTypes.string,
  containerClass: PropTypes.string,
};

export default Carousel;
