import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import VerticalSolid from './VerticalSolid';
import VerticalDotted from './VerticalDotted';

import styles from './styles.module.css';

class TimelineLayout extends React.Component {

  constructor(props) {

    super(props);

    const components = {
      verticalSolid: VerticalSolid,
      verticalDotted: VerticalDotted,
    };

    const align = props.section.styles.align ? props.section.styles.align : 'Center';

    this.state = {
      align,
      components,
    };

    this.createElement = this.createElement.bind(this);
    this.component = this.component.bind(this);

  }

  createElement(item, index, subIndex, itemAlign) {

    const bgColor = {
      backgroundColor: this.props.themeData.colors[0],
    };

    let alignStyle;
    if (subIndex === undefined) alignStyle = `align${this.state.align}`;

    let elem;
    if (item.type.startsWith('HEADINGS/') || item.type === 'PARAGRAPH/PARAGRAPH') {

      const id = `${this.props.section._id}_${item.type.startsWith('HEADINGS/') ? 'Title' : 'Paragraph'}_${subIndex !== undefined ? subIndex : ''}${index}_section`;
      let content;
      let styleName1;

      if (item.type === 'HEADINGS/HEADING-TWO') {

        content = `<h2>${item.text}</h2>`;
        styleName1 = 'timelineHeader';

      } else if (item.type === 'HEADINGS/HEADING-FOUR') {

        content = `<h4>${item.text}</h4>`;
        styleName1 = 'timelineHeader';

      } else if (item.type === 'HEADINGS/HEADING-FIVE') {

        content = `<h5>${item.text}</h5>`;
        styleName1 = 'timelineText';

      } else if (item.type === 'PARAGRAPH/PARAGRAPH') {

        content = `<span>${item.text}</span>`;
        styleName1 = 'timelineText';

      }

      const text = (
        <div>
          {HTMLParser(content)}
        </div>
      );

      elem = (
        <div
          key={id}
          className={`${styles[styleName1]}${alignStyle !== undefined ? ` ${styles[alignStyle]}` : ''}`}
        >
          <div>
            { text }
          </div>
        </div>
      );

    } else if (item.type === 'IMAGES/IMAGE') {

      const positioner = `${this.props.layout}Positioner${item.content.icon ? 'Icon' : ''}`;
      const align = `align${itemAlign}`;
      let wrapper;
      let imgWrapper = '';
      let img = '';
      if (this.props.layout === 'verticalDotted') {

        wrapper = `timelineImageWrapper${item.content.icon ? 'Icon' : ''}`;
        img = 'imageContent2';

      } else if (this.props.layout === 'verticalSolid') {

        wrapper = item.content.icon ? 'timelineImageWrapperIcon' : 'timelineImageWrapperSolid';
        imgWrapper = item.content.icon ? 'imageContent2' : 'imageContent3';

      }

      let hasBG;
      if (
        (item.content.icon && this.props.layout === 'verticalDotted')
        || this.props.layout === 'verticalSolid'
      ) hasBG = true;

      elem = (
        <div
          key={`${this.props.section._id}_Image}_${subIndex !== undefined ? subIndex : ''}${index}_section`}
          className={`${styles[positioner]}${align !== undefined ? ` ${styles[align]}` : ''}`}
        >
          <div
            className={styles[wrapper]}
            style={hasBG ? bgColor : {}}
          >
            <SectionMedia
              mediaType={item.content.icon ? 'ICON' : 'IMAGE'}
              wrapperStyle={imgWrapper}
              elementStyle={img}
              iconStyle=""
              src={`${process.env.IMAGES_CDN}/${item.content.src}`}
              alt={item.content.alt}
              data={item.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              colors={this.props.themeData.colors}
            />
          </div>
        </div>
      );

    }

    return elem;

  }

  component(id, compContent, index, align, bg, color, first, last, layout, imgIcon) {

    const SpecificComponent = this.state.components[layout];

    return (
      <SpecificComponent
        key={`${id}_Component_${index}`}
        id={id}
        compContent={compContent}
        index={index}
        align={align}
        bg={bg}
        color={color}
        first={first}
        last={last}
        imgIcon={imgIcon}
        images={this.props.images}
      />
    );

  }

  render() {

    const content = [];
    let last;
    let first;
    let imgIcon;
    this.props.section.data.forEach((item, index) => {

      if (item.type === 'COMPONENT' && item.active === true) {

        last = index;
        if (first === undefined) first = index;

      }

    });

    this.props.section.data.forEach((item, index) => {

      if (item.active === true) {

        if (item.type === 'COMPONENT') {

          const compContent = [];
          item.data.forEach((compItem, compIndex) => {

            if (compItem.active === true) {

              if (compItem.type === 'IMAGES/IMAGE') imgIcon = compItem.content.icon;

              const elem = this.createElement(compItem, index, compIndex, item.align);
              compContent.push(elem);

            }

          });

          let bg;
          if (item.styles.backgroundColor.active) {

            let { backgroundColor } = item.styles;
            if (
              item.styles.backgroundColor.solid === ''
              && item.styles.backgroundColor.gradient.from === ''
            ) backgroundColor = { solid: '#ffffff' };

            bg = formColor(
              backgroundColor,
              false,
              item.styles.backgroundColor.opacity,
              undefined,
              this.props.themeData.colors,
            );

          }

          const component = this.component(
            this.props.section._id,
            compContent,
            index,
            item.align,
            bg,
            this.props.themeData.colors[0],
            first,
            last,
            this.props.layout,
            imgIcon,
          );
          content.push(component);

        } else {

          const elem = this.createElement(item, index, undefined);
          content.push(<div key={`${this.props.section._id}_Element_${index}`} className={`col-12 col-md-7 ${index === 0 ? styles.firstElem : ''}`}>{elem}</div>);

        }

      }

    });

    return (
      <div className={`container ${styles[`timelineContainer${this.props.full}`]}`}>
        <div className={`row ${styles.timelineRow}`}>
          { content }
        </div>
      </div>
    );

  }

}

TimelineLayout.propTypes = {
  layout: PropTypes.string,
  full: PropTypes.string,
  section: PropTypes.shape({}),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default TimelineLayout;
