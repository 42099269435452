import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import Axios from 'axios';

import instagramLogo from '../../../../../api/instagrem.svg';

import ButtonEditor from '../../ButtonEditor';
import Carousel from '../../../Carousel';

import styles from './styles.module.css';

const axios = Axios.create({
  withCredentials: false,
});

class InstagramLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const profileIndex = props.section.data.findIndex(d => d.type === 'PROFILE/INSTAGRAM');

    const align = props.section.styles.align
      ? props.section.styles.align : 'Left';

    const mobileCarousel = profileIndex > -1
      && props.section.data[profileIndex].mobile_carousel;
    const imageCount = profileIndex > -1 && props.section.data[profileIndex].image_count
      ? props.section.data[profileIndex].image_count : 12;
    const accessToken = profileIndex > -1 && props.section.data[profileIndex].access_token;
    const username = profileIndex > -1 && props.section.data[profileIndex].username
      ? props.section.data[profileIndex].username : '';
    const columnAmount = profileIndex > -1 && props.section.data[profileIndex].column_amount
      ? props.section.data[1].column_amount : 6;

    this.state = {
      align,
      layout: props.layout,
      legacyName: props.section.data[1].username || '',
      expires: props.section.data[1].expires,
      mobileCarousel,
      imageCount,
      accessToken,
      username,
      columnAmount,
    };

    this.handleImageError = this.handleImageError.bind(this);

  }

  componentDidMount() {

    this.componentIsMounted = true;
    if (this.state.accessToken) this.getInstagramData();

  }

  componentWillUnmount() {

    this.componentIsMounted = false;

  }

  getInstagramData() {

    const { accessToken } = this.state;

    if ((this.state.instagramData && this.state.instagramData.length > 0)
      || !accessToken || accessToken.length < 8) {

      if (!this.componentIsMounted) {

        return;

      }

      this.setState({
        instagramData: [],
      });

      return;

    }

    if (this.state.expires) {

      const url = `https://graph.instagram.com/me/media?access_token=${accessToken}&fields=media_url,media_type,username,thumbnail_url`;

      axios.get(url).then(response => {

        let { username } = this.state;

        response.data.data.forEach(d => {

          if (d.username && d.username.length > 0) {

            // eslint-disable-next-line prefer-destructuring
            username = d.username;

          }

        });

        const instagramData = response.data.data
          .filter(i => (i.media_type === 'IMAGE' || i.media_type === 'VIDEO'))
          .slice(0, this.state.imageCount || 12)
          .map(d => ({
            media_url: (d.media_type === 'IMAGE' ? d.media_url : d.thumbnail_url),
          }));

        this.setState({
          instagramData,
          username,
        });

      })
        .catch(e => {

          console.log(e);

          this.setState({
            instagramData: [],
          });

        });

    } else { // backwards compatibility (only useful until march 31st 2020):

      const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=${this.state.imageCount || 12}`;

      if ((this.state.instagramData && this.state.instagramData.length > 0)
        || !accessToken || accessToken.length < 8) {

        if (!this.componentIsMounted) {

          return;

        }

        this.setState({
          instagramData: [],
        });

        return;

      }

      axios.get(url)
        .then(response => {

          this.setState({
            instagramData: response.data.data,
          });

        })
        .catch(err => {

          console.log(err);
          this.setState({
            instagramData: [],
          });

        });

    }

  }

  handleImageError(index) {

    const newInstaData = this.state.instagramData;

    if (!newInstaData) return;

    newInstaData[index].hidden = true;

    this.setState({
      instagramData: newInstaData,
    });

  }

  render() {

    const colGridWidth = 12 / this.state.columnAmount;
    const bootstrapGridStyle = `col-${colGridWidth} col-sm-${colGridWidth} col-md-${colGridWidth} col-lg-${colGridWidth}`;
    const alignStyle = `align${this.state.align}`;
    const full = this.props.full ? 'Full' : '';

    // Carousel stuff
    const arrows = false;
    const cards = [];

    if (this.state.instagramData && this.state.instagramData.length > 0) {

      this.state.instagramData.map((item, index) => {

        let itemText = 'Instagram image';

        if ((item.images && item.media_url && !item.hidden)
          || (
            item.images && item.images.standard_resolution && !item.hidden
          )) {

          const mediaUrl = item.images.media_url || item.images.standard_resolution;

          if (item.caption && item.caption.text) {

            itemText = item.caption.text;

          }

          const card = (
            <div key={`instagramImage_card_${index}`} className={styles.carouselWrapper}>
              <div className={styles.carouselImageWrapper}>
                <img
                  src={mediaUrl}
                  alt={itemText}
                  className={styles.carouselImg}
                />
              </div>
            </div>
          );

          cards.push(card);


        }

        return null;

      });

    }

    const top = [];
    const content = [];

    this.props.section.data.map((elem, i) => {

      if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {

        const text = `<h2>${elem.text}</h2>`;

        const result = (
          <div
            key={`${this.props.section._id}_layout_${i}`}
            className={`${styles.instagramHeader} ${styles[alignStyle]}`}
          >
            {HTMLParser(text)}
          </div>
        );

        top.push(result);

      } else if (elem.active && elem.type.startsWith('BUTTONS')) {

        const link = {
          linkData: {
            link: `https://instagram.com/${this.state.username || this.state.legacyName}`,
          },
          openLinkInNewTab: true,
          type: 'EXTERNAL',
        };

        const newData = elem.content;
        newData.linkObj = link;

        const result = (
          <div
            key={`${this.props.section._id}_layout_${i}`}
            className={styles.instagramBtnWrapper}
          >
            <ButtonEditor
              buttons={this.props.buttons}
              themeData={this.props.themeData}
              button="Secondary"
              data={newData}
              image={instagramLogo}
            />
          </div>
        );

        content.push(result);

      }

      return null;

    });

    return (
      <div className={`${full ? '' : 'container '}${styles[`instagramContainer${full}`]}`}>
        { top }
        {
          <div className={`row ${styles.instagramRowWrapper} ${styles[alignStyle]}`}>
            {
              [
                ...content,
                (!this.props.mobileCarousel || !this.props.mobileView)
                  ? (this.state.instagramData
                    && this.state.instagramData.map((instaItem, instaIndex) => {

                      if ((instaItem.media_url && !instaItem.hidden)
                        || (
                          instaItem.images
                          && instaItem.images.standard_resolution
                          && !instaItem.hidden
                        )) {

                        const mediaUrl = instaItem.media_url
                          || instaItem.images.standard_resolution.url;

                        return (
                          <div
                            key={`${this.props.section._id}_section_1_instagramFeedImage_${instaIndex + 1}`}
                            className={`${bootstrapGridStyle} ${this.props.layout === 'gutters'
                              ? styles.instagramGuttersImage
                              : styles.instagramNoGuttersImage}`}
                            onError={() => this.handleImageError(instaIndex)}
                          >
                            <div className={styles.instagramImageWrapper}>
                              <img
                                key={`section_1_instaImage_${instaIndex + 1}`}
                                alt=""
                                className={styles.instagramImg}
                                src={mediaUrl}
                              />
                            </div>
                          </div>
                        );

                      }

                      return null;

                    }))
                  : ((cards && cards.length > 0)
                    && (
                      <div
                        key={`${this.state.page}_page_${this.props.section._id}_section_1_carouselContainerDiv`}
                        className={`${this.props.full ? styles.carouselContainerFull : styles.carouselContainer}`}
                      >
                        <div
                          key={`${this.state.page}_page_${this.props.section._id}_section_1_carouselDiv`}
                          className={`row ${this.props.full ? styles.noMargin : ''}`}
                        >
                          <div className={`col-12 ${styles.carouselCol}`} key={`${this.props.section._id}_section_1_carouselDiv2`} >
                            <Carousel
                              showArrows={arrows}
                              showIndicators
                              autoPlay
                              infiniteLoop
                              interval={5}
                            >
                              { cards }
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    )),
              ]
            }
          </div>
        }
      </div>
    );

  }

}

InstagramLayout.propTypes = {
  mobileCarousel: PropTypes.bool,
  align: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.array,
  }),
  layout: PropTypes.string,
  full: PropTypes.string,
};


export default InstagramLayout;
