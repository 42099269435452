import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import SectionMedia from '../SectionMedia';
import { formColor } from '../../../../helper';

import styles from './styles.module.css';

class PreviewMilestonesLayout extends React.Component {

  constructor(props) {

    super(props);

    const dataIndex = props.section.data.findIndex(elem => elem.type === 'DATA');

    const align = props.section.styles.align ? this.props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    const animationActive = dataIndex > -1 && props.section.data[dataIndex].animationActive;
    const animationDuration = dataIndex > -1 && props.section.data[dataIndex].animationDuration
      ? props.section.data[dataIndex].animationDuration : 4;

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    const animationStarted = [true, true];

    if (props.section && props.section.data) {

      props.section.data.map((data) => {

        if (data.type === 'COMPONENT') {

          animationStarted.push(false);

        }

        return null;

      });

    }

    this.state = {
      full,
      align,
      animationActive,
      animationDuration,
      animationStarted,
      columns,
    };

    this.animationStarted = this.animationStarted.bind(this);

  }

  animationStarted(isVisible, start, index) {

    if (isVisible && !this.state.animationStarted[index]) {

      start();

      let change = this.state.animationStarted;
      change[index] = true;

      this.setState({
        animationStarted: change,
      });

    }

  }

  render() {

    let columns;
    if (this.state.columns === 1) columns = 'col-12';
    else columns = `col-12 col-md-6 col-lg-${this.state.columns ? (12 / this.state.columns) : 3}`;

    const milestones = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        const elems = [];
        item.data.forEach((elem, i) => {

          if (elem.active && elem.type === 'IMAGES/IMAGE') {

            elems.push(<SectionMedia
              key={`imageItem_${this.props.section._id}_${index}${i}`}
              mediaType={(elem.content.icon) ? 'ICON' : 'IMAGE'}
              wrapperStyle="milestonesImageWrapper"
              elementStyle="milestonesImage"
              iconStyle="milestonesImage"
              align={item.align}
              src={elem.content.CDNLink
                ? elem.content.CDNLink
                : `${process.env.IMAGES_URL}/${elem.content.src}`}
              alt={elem.content.alt}
              data={elem.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              colors={this.props.themeData.colors}
            />);

          } else if (elem.active && (elem.type.startsWith('HEADINGS/') || elem.type === 'PARAGRAPH/PARAGRAPH')) {

            let styleName;
            let data;
            if (elem.type.startsWith('HEADINGS/')) {

              if (elem.type === 'HEADINGS/HEADING-TWO') {

                styleName = styles.exceptionWeight;
                const rawData = elem.text.replace(/\D+/g, '');
                const value = parseInt(rawData, 10);
                data = this.state.animationActive
                  ? (
                    <CountUp
                      end={value}
                      redraw
                      delay={0.2}
                      useEasing
                      onEnd={pauseResume => pauseResume}
                      duration={parseInt(this.state.animationDuration, 10)}
                    >
                      {
                        ({ countUpRef, start }) => (
                          <VisibilitySensor
                            onChange={isVisible => this.animationStarted(isVisible, start, index)}
                            delayedCall
                          >
                            <h2 ref={countUpRef} />
                          </VisibilitySensor>
                        )
                      }
                    </CountUp>
                  )
                  : (
                    <h2>
                      {value}
                    </h2>
                  );

              } else {

                styleName = styles.milestonesSubtitle;
                data = HTMLParser(`<h3>${elem.text}</h3>`);

              }

            } else {

              styleName = `${styles.milestonesComponentParagraph} ${styles.exceptionLineHeight}`;
              data = HTMLParser(`<span>${elem.text}</span>`);

            }

            const result = (
              <div
                key={`textItem_${this.props.section._id}_${index}${i}`}
                className={styleName}
              >
                { data }
              </div>
            );

            elems.push(result);

          }


        });

        let style;
        if (item.styles.backgroundColor.active) {

          let { backgroundColor } = item.styles;
          if (
            item.styles.backgroundColor.solid === ''
            && item.styles.backgroundColor.gradient.from === ''
          ) backgroundColor = { solid: '#ffffff' };

          style = formColor(
            backgroundColor,
            false,
            item.styles.backgroundColor.opacity,
            undefined,
            this.props.themeData.colors,
          );

        }

        if (style !== undefined) {

          style.paddingTop = '15px';
          style.paddingBottom = '15px';

        }

        const result = (
          <div
            key={`${this.props.section._id}_layout_${index}`}
            className={`${columns} ${styles.milestonesComponentWrapper} ${styles[`align${item.align}`]}`}
            style={style}
          >
            { elems }
          </div>
        );

        milestones.push(result);

      } else if (item.active && item.type.startsWith('HEADINGS/')) {

        let styleName;
        let data;
        if (item.type === 'HEADINGS/HEADING-TWO') {

          styleName = `col-12 ${styles.milestonesMainHeader}`;
          data = `<h2>${item.text}</h2>`;

        } else {

          styleName = `col-12 ${styles.milestonesSubHeader}`;
          data = `<h4>${item.text}</h4>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        top.push(text);

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`milestonesContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { milestones }
        </div>
      </div>
    );

  }

}

PreviewMilestonesLayout.propTypes = {
  mobileView: PropTypes.bool,
  page: PropTypes.string,
  domain: PropTypes.string,
  section: PropTypes.shape({}),
  align: PropTypes.string,
  layout: PropTypes.string,
  full: PropTypes.string,
  animationActive: PropTypes.bool,
  animationDuration: PropTypes.number,
};

export default PreviewMilestonesLayout;
